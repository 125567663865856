import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'markdown-to-jsx';
import {
  ToggableContent as IProps,
} from 'infrastructure/gatsby/types/toggable-content';
import MinimalAccordionTemplate
  from '@shipae/components-sandbox/component/minimal-accordion';
import {
  PrimaryButton,
} from '@shipae/components-sandbox/component/buttons';
import { MobileSection } from '../../shared/mobile-section';
import { Heading as SharedHeading } from '../../shared/heading';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

const Heading = styled(SharedHeading).attrs({ as: 'div' })``;

const RichTextHeader = styled.div`
  font-size: 2.8rem;
  font-weight: bold;
`;

const richTextOverrides = {
  a: {
    component: PrimaryButton,
  },
  h1: {
    component: RichTextHeader,
  },
  h2: {
    component: RichTextHeader,
  },
  h3: {
    component: RichTextHeader,
  },
  h4: {
    component: RichTextHeader,
  },
  h5: {
    component: RichTextHeader,
  },
  h6: {
    component: RichTextHeader,
  },
};

const MobileToggableContent: React.FC<Omit<IProps, 'id' | 'type'>> = ({
  sectionId,
  contentItems = [],
  title,
}) => (
  <MobileSection id={sectionId}>
    <Heading>{title}</Heading>
    <MinimalAccordionTemplate
      entries={contentItems.map((item) => ({
        title: item.title || '',
        content: (
          <>
            <ReactMarkdown
              options={{
                ...gloabalReactMdOptions,
                overrides: richTextOverrides,
              }}
            >
              {item.description || ''}
            </ReactMarkdown>
            <ReactMarkdown
              options={{
                ...gloabalReactMdOptions,
                overrides: richTextOverrides,
              }}
            >
              {item.columnLayoutContent || ''}
            </ReactMarkdown>
          </>
        ),
      }))}
    />
  </MobileSection>
);

export default MobileToggableContent;
