import React from 'react';
import {
  ToggableContent as IProps,
} from 'infrastructure/gatsby/types/toggable-content';
import Desktop from './desktop';
import Mobile from './mobile';

const ToggableContent: React.FC<IProps> = ({
  title,
  colorTheme,
  contentItems,
}) => (
  <>
    <Desktop
      title={title}
      colorTheme={colorTheme}
      contentItems={contentItems}
    />
    <Mobile
      title={title}
      colorTheme={colorTheme}
      contentItems={contentItems}
    />
  </>
);

export default ToggableContent;
