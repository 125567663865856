import { media } from 'src/views/theme';
import styled from 'styled-components';

export const MobileSection = styled.section`
  display: none;
  padding: 6rem calc((100% - var(--container-width)) / 2);

  ${ media.max(767) } {
    display: block;
  }
`;
