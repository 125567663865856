import React from 'react';
import {
  ToggableContent as IProps,
} from 'infrastructure/gatsby/types/toggable-content';
import ToggableContentTemplate, { ToggableContentTheme }
  from '@shipae/components-sandbox/component/toggable-content';
import SDTabsTheme
  from '@shipae/components-sandbox/component/tabs/theme/shipa-delivery';
import SDTabTheme
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-delivery';
import SECTabsTheme
  from '@shipae/components-sandbox/component/tabs/theme/shipa-ecommerce';
import SECTabTheme
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-ecommerce';
import SFTabsTheme
  from '@shipae/components-sandbox/component/tabs/theme/shipa-freight';
import SFTabTheme
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-freight';
import RichText, {
  RichTextTheme,
} from '@shipae/components-sandbox/component/rich-text';
import {
  PrimaryButton,
} from '@shipae/components-sandbox/component/buttons';
import ReactMarkdown from 'markdown-to-jsx';
import styled, { css } from 'styled-components';
import { fonts, media } from 'src/views/theme';
import { DesktopSection as Section } from '../../shared/desktop-section';
import { Heading } from '../../shared/heading';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

interface BackgroundImageProps {
  url: string;
  isHeightFixed: boolean;
}

const BackgroundImage = styled.div<BackgroundImageProps>`
  width: 100%;
  height: ${ (pr) => (pr.isHeightFixed ? '50rem' : 'auto') };
  background-image: url('${ (pr) => pr.url }');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const DesktopSection = styled(Section)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`;

const sharedTabCss = css`
  font-size: 2rem;
  font-weight: 300;
`;

const toggableContentTheme = {
  ...ToggableContentTheme,
  Tab: {
    ...ToggableContentTheme.Tab,
    Main: styled(ToggableContentTheme.Tab.Main).attrs({ as: 'h3' })`
      ${ sharedTabCss }
    `,
  },
};
const SDTheme = {
  ...toggableContentTheme,
  Tabs: SDTabsTheme,
  Tab: {
    ...SDTabTheme,
    Main: styled(SDTabTheme.Main).attrs({ as: 'h3' })`
      ${ sharedTabCss }
    `,
  },
};
const SECTheme = {
  ...toggableContentTheme,
  Tabs: SECTabsTheme,
  Tab: {
    ...SECTabTheme,
    Main: styled(SECTabTheme.Main).attrs({ as: 'h3' })`
      ${ sharedTabCss }
    `,
  },
};
const SFTheme = {
  ...toggableContentTheme,
  Tabs: SFTabsTheme,
  Tab: {
    ...SFTabTheme,
    Main: styled(SFTabTheme.Main).attrs({ as: 'h3' })`
      ${ sharedTabCss }
    `,
  },
};

const Flex = styled.div`
  padding: 0 calc((100% - var(--container-width)) / 2) 6rem;
  width: 100%;
  display: flex;

  ${ media.sm } {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 55%;

  ${ media.sm } {
    flex-basis: auto;
  }
`;

const CustomRichTextTheme = {
  ...RichTextTheme,
  Main: styled(RichTextTheme.Main)`
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
    a, a:visited, a:active {
      ${ fonts.bodyl200 };
      ${ media.max(600) } {
        ${ fonts.displayxs200 };
      }

      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    img {
      display: inline-block;
      margin-right: 0.5rem;
      margin-top: 3rem;
      margin-bottom: -2rem;
    }

    p {
      img + a {
        display: inline-block;
        transform: translateY(-0.5rem);
      }
    }
  `,
};

const DesktopToggableContent: React.FC<Omit<IProps, 'id' | 'type'>> = ({
  sectionId,
  contentItems = [],
  colorTheme,
  title,
}) => {
  let theme = toggableContentTheme;
  switch (colorTheme) {
    case 'shipaDelivery':
      theme = SDTheme;
      break;
    case 'shipaEcommerce':
      theme = SECTheme;
      break;
    case 'shipaFreight':
      theme = SFTheme;
      break;
    default:
      break;
  }

  const renderImage = (
    url?: string,
    alt?: string,
    isHeightFixed?: boolean,
  ) => (url
    ? (
      <BackgroundImage
        url={url}
        isHeightFixed={isHeightFixed || false}
        role="img"
        aria-label={alt}
      />
    )
    : undefined);

  const renderColumns = (markdown: string, url?: string, alt?: string) => (
    <Flex>
      <LeftColumn>
        <RichText theme={CustomRichTextTheme}>
          <ReactMarkdown
            options={{
              ...gloabalReactMdOptions,
              overrides: {
                a: {
                  component: PrimaryButton,
                },
              },
            }}
          >
            {markdown || ''}
          </ReactMarkdown>
        </RichText>
      </LeftColumn>
      {renderImage(url, alt, false)}
    </Flex>
  );

  return (
    <DesktopSection id={sectionId}>
      <Heading>{title}</Heading>
      <ToggableContentTemplate
        contentItems={contentItems.map((item) => ({
          ...item,
          description: item.description && (
          <ReactMarkdown
            options={{
              ...gloabalReactMdOptions,
              overrides: {
                a: {
                  component: PrimaryButton,
                },
              },
            }}
          >
            {item.description || ''}
          </ReactMarkdown>
          ),
          image: item.columnLayoutContent
            ? renderColumns(
              item.columnLayoutContent,
              item.illustration?.localFile?.publicURL,
              item.illustration?.alternativeText,
            )
            : renderImage(
              item.illustration?.localFile?.publicURL,
              item.illustration?.alternativeText,
              true,
            ),
        }))}
        theme={theme}
      />
    </DesktopSection>
  );
};

export default DesktopToggableContent;
